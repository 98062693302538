<template>
    <div>
        <v-icon small>launch</v-icon>
    </div>
</template>

<script>
export default {

}
</script>